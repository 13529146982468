<template>
  <div>
    <div class="upload_list">
      <div class="uploading">
        <div class="context_upload">
          <el-row id="head">
            <el-col :span="6">
              <span class="ft">素 材</span>
            </el-col>
            <el-col :span="3">
              <span class="ft">格 式</span>
            </el-col>
            <el-col :span="3">
              <span class="ft">大小</span>
            </el-col>
            <el-col :span="4">
              <span class="ft">上传进度</span>
            </el-col>
            <el-col :span="4">
              <span class="ft">上传时间</span>
            </el-col>
            <el-col :span="4">
              <span class="ft">操 作</span>
            </el-col>
            <el-divider></el-divider>
          </el-row>
          <div class="listcontain" ref="listcontain" v-infinite-scroll="loadMore" :infinite-scroll-immediate="false">
            <div class="itemContain" v-for="(item,i) in uploadList" :key="i">
              <el-row id="item">
                <el-col :span="6">
                  <el-tooltip effect="dark" :content="item.name" placement="bottom" popper-class="uploading_tooltip">
                    <span class="metirial_name one_line_ellipsis">{{item.name}}</span>
                  </el-tooltip>
                </el-col>
                <el-col :span="3">
                  <span>{{item.format}}</span>
                </el-col>
                <el-col :span="3">
                  <p>{{item.total_size | transformByte}}</p>
                  <!-- <p style="padding-top: 10px">{{item.resolution}}</p>
                  <p style="padding-top: 10px" v-if="item.type == 1">{{item.duration}}</p> -->
                </el-col>
                <el-col :span="4" v-if="item.finish_if == 1">
                   <span>上传完成</span>
                </el-col>
                <el-col :span="4" v-if="item.finish_if == -1">
                   <span>已取消</span>
                </el-col>
                <el-col :span="4">
                  {{item.insert_time | formatTime}}
                </el-col>
                <el-col :span="4">
                  <div v-if="item.finish_if == 1 || item.finish_if == -1">
                  <el-tooltip effect="dark" content="删除该记录" placement="top">
                    <i
                      @click="deleHistory(item.id)"
                      style="cursor: pointer"
                      class="iconfont icon-shanchujilu"
                    ></i>
                  </el-tooltip>
                  </div>
                </el-col>
              </el-row>
              <el-divider></el-divider>
            </div>
            <p v-if="loading" class="load_sty">加载中...</p>
            <p v-if="noMore && uploadList.length" class="load_sty">没有更多了~</p>
            <noResult v-if="uploadList.length == 0  && !loading" iconUrl="icon-meiyousucai" iconSty="font-size:80px" textSty="font-size:18px" noResultText="暂无数据"></noResult>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import noResult from '../component/noResult.vue'
export default {
  inject:['reload'],
  components: {
      noResult,
  },
  data() {
    return {
      uploadList: [],
      loading: false,
      noMore: false,
      busyScroll: false,

      pageCount: 1, //页码
      limitCount: 40, //每页条数
    };
  },
  computed: {
    uploadingPage:{
      get() {
        return this.$store.state.uploading
      },
      set(val){
          // this.$store.commit('uploadingPage', val)
      }
    }
  },
  watch: {
    uploadingPage(val) {
      if(val == true){
        this.reload();
      }
    },
  },

  created () {
    this.$store.commit('uploadingPage', false)
    this.getUploadList('finish') //上传列表
  },
  mounted () {
  },
  methods: {
     // 删除历史
    deleHistory (id) {
      const formData = new FormData()
      formData.append("id",id)
      this.$http({
        method: 'POST',
        url: '/upload/delete_task/',
        data: formData
      }).then(res => {
        if (res.data.status === 0) {
          this.uploadList.forEach((item,i)=>{
            if(item.id == id) {
              this.uploadList.splice(i,1)
              return
            }
          })
        } else {

        }
      }).catch(error => console.log(error))
    },
    loadMore(){
      this.loading = true
      if(!this.busyScroll){
        this.noMore = false
        setTimeout(() => {
            this.pageCount += 1
            this.getUploadList()
          }, 2000)
      }else{
        this.noMore = true
        this.loading = false
      }
    },
    // 上传列表
   getUploadList (flag) {
       this.$store.commit('uploadingPage', false)
       return this.$http({
          method: 'GET',
          url: '/upload/get_upload_list/',
          params: {
            page: this.pageCount,  //页码，从1,开始
            page_num: this.limitCount,  //每页数量、
            flag: flag == 'finish' ? 1:0
          }
        }).then(res => {
          if (res.data.status === 0) {
            if(res.data.result.length == 0){
              this.busyScroll = true
              this.noMore  = true
            }
            else {
                this.busyScroll = false
            }

            this.loading = false
            this.uploadList = this.uploadList.concat(res.data.result);
          } else {
              this.$message.error(res.data.msg);
          }
        }).catch(error => console.log(error))
    },
  },
  destroyed (){
    this.$store.commit('uploadingPage', false)
  }

};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixin.scss';
  .uploading {
  // 内容
  .context_upload {
    padding: 40px 3.5vw 0 3.2vw;
    overflow: hidden;
    // 列表头部
    #head {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.9);
      text-align: center;
      // 分割线
      .el-divider--horizontal {
        display: block;
        height: 1px;
        width: 100%;
        margin: 30px 0px 0px 0px;
        background: #3e3d4c;
      }
      .ft{
        font-size: 16px;
      }
    }
    // 全部列表容器
    .listcontain {
      height: 80vh;
      @include scrollbar(6px,7px);
      // 每一项容器
      .itemContain {
        // 列表项
        #item {
          height: 116px;
          display: flex;
          align-items: center;
          text-align: center;
          .metirial_name {
            display: block;
          }
        }
        // 分割线
        .el-divider--horizontal {
          display: block;
          height: 1px;
          width: 100%;
          margin: 0px 0px 0px 0px;
          background: #3e3d4c;
        }
      }
      .load_sty{
        text-align:center;
        color:rgba(255,255,255,0.6);
        margin-top:30px;
      }
    }
  }
  }
</style>
<style lang="scss">
.uploading_tooltip.el-tooltip__popper{
  max-width: 18%;
  line-height: 20px;
}
</style>
